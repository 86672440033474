$inspinia-path: '../../../../3rd-party/Inspinia/SCSS';

/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
*/
/*
 * Variable override
 * Bluugo Tracking Cloud styles
 */

// Font
$font-stack: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Basic Colors
$navy: #0c9fd7;           // Primary color
$dark-gray: #c2c2c2;      // Default color
$blue: #3CBD33;           // Success color
$lazur: #23c6c8;          // Info color
$yellow: #f8ac59;         // Warrning color
$red: #ed5565;            // Danger color

// Various colors
$text-color: #676a6c;           // Body text
$gray: #f3f3f4;                 // Background wrapper color
$light-gray: #d1dade;           // Default label, badget
$label-badget-color: #5e5e5e;
$light-blue: #f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;   // IBox border
$ibox-title-bg: white;    // IBox Background header
$ibox-content-bg: white;  // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: image-url("patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2f4050;
$nav-profil-pattern: image-url("patterns/header-profile.png") no-repeat;
$nav-text-color: #a7b1c2;

// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Variables, Mixins
// @import "../../../../3rd-party/Inspinia/SCSS/variables";
@import "../../../../3rd-party/Inspinia/SCSS/mixins";

// INSPINIA Theme Elements
@import "../../../../3rd-party/Inspinia/SCSS/typography";
@import "../../../../3rd-party/Inspinia/SCSS/navigation";
@import "../../../../3rd-party/Inspinia/SCSS/top_navigation";
@import "../../../../3rd-party/Inspinia/SCSS/buttons";
@import "../../../../3rd-party/Inspinia/SCSS/badgets_labels";
@import "../../../../3rd-party/Inspinia/SCSS/elements";
@import "../../../../3rd-party/Inspinia/SCSS/sidebar";
@import "../../../../3rd-party/Inspinia/SCSS/base";
@import "../../../../3rd-party/Inspinia/SCSS/pages";
// @import "../../../../3rd-party/Inspinia/SCSS/chat";
@import "../../../../3rd-party/Inspinia/SCSS/metismenu";
@import "../../../../3rd-party/Inspinia/SCSS/spinners";

// Landing page
// @import "../../../../3rd-party/Inspinia/SCSS/landing";

// RTL Support
// @import "../../../../3rd-party/Inspinia/SCSS/rtl";

// INSPINIA Skins
@import "../../../../3rd-party/Inspinia/SCSS/skins";
@import "../../../../3rd-party/Inspinia/SCSS/md-skin";

// Media query style
@import "../../../../3rd-party/Inspinia/SCSS/media";


// Apply before js is loaded
@media (max-width: 768px) {
  .navbar-static-side {
    display: none;
    z-index:2001;
    position: absolute;
    width: 70px;
  }
} 